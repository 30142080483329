<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:selection="selected"
      :value="items"
      :row-hover="true"
      :rows="10"
      dataKey="id"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      paginator
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
      <Column field="group" header="Modul" style="max-width: 70px" sortable>
        <template #body="{ data }">
          {{ data.group }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="groups"
            class="p-column-filter"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="name"
        header="Menu"
      />
    </DataTable>
    <div class="flex justify-content-end align-items-end">
      <Button
        label="Simpan"
        :loading="loading"
        icon="pi pi-check"
        @click="onSavePermission"
      />
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    selectedPermission: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    groups() {
      const result = [...new Set(this.items.map((item) => item.group))]
      return result.sort()
    },
  },
  data() {
    return {
      selected: this.selectedPermission,
      filters: {
        group: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    }
  },
  methods: {
    onSavePermission() {
      const result = this.selected.map((el) => {
        return el.id
      })
      this.$emit('save', result)
    },
  },
}
</script>
