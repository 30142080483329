<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Role</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small"
          >/ Master / User & akses / Role & Permission</span
        >
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Role & Permission"
          class="mr-2"
          @click="onAddData"
        />
      </div>
      <grid-role :items="roles" :loading="isLoading" @edit="onEditData" />
    </div>
    <Dialog
      header="Edit Role"
      v-model:visible="dialogRole"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
      maximizable
    >
      <form-role
        :item="form"
        :permissions="permissions"
        :selected="selected"
        :editMode="editMode"
        @close="onFormClose"
      />
    </Dialog>
  </div>
</template>

<script>
import GridRole from '@/components/master/GridRole'
import FormRole from '@/components/master/FormRole'
import RolePermissionService from '@/services/RolePermissionService'

export default {
  components: {
    FormRole,
    GridRole,
  },
  data() {
    return {
      isLoading: false,
      dialogRole: false,
      roles: [],
      selected: [],
      permissions: [],
      form: {},
      editMode: false,
    }
  },
  mounted() {
    const rolePermissionService = new RolePermissionService()
    this.isLoading = true
    rolePermissionService.get().then((res) => {
      this.roles = res.data.data
      this.isLoading = false
    })
    rolePermissionService.getPermission().then((res) => {
      this.permissions = res.data.data
    })
  },
  methods: {
    loadData() {
      const rolePermissionService = new RolePermissionService()
      this.isLoading = true
      rolePermissionService.get().then((res) => {
        this.roles = res.data.data
        this.isLoading = false
      })
    },
    onAddData() {
      this.form = {
        name: null,
      }
      this.selected = []
      this.dialogRole = true
    },
    onEditData(e) {
      const rolePermissionService = new RolePermissionService()
      rolePermissionService.getByIdPermission(e.id).then((res) => {
        this.selected = res.data.data
        this.form = { ...e } // Use spread operator to create a shallow copy
        this.editMode = true
        this.dialogRole = true
      })
    },
    onFormClose() {
      this.loadData()
      this.dialogRole = false
    },
  },
}
</script>
